const videoContainer = document.querySelector(".videoBlock__frameContainer");

const { videoID } = JSON.parse(videoContainer.dataset.video),
  options = {
    id: videoID,
    controls: false,
    loop: true,
  },
  player = new Vimeo.Player(videoContainer, options);

window.player = player;

const autoplay = (entries, observer) => {
  console.log(`entries`, entries);
  console.log(`observer`, observer);

  if (entries[0].isIntersecting) {
    player.play();
  } else {
    player.pause();
  }
};

const observerOptions = {
  threshold: 0.6,
};

const observer = new IntersectionObserver(autoplay, observerOptions);

observer.observe(videoContainer);
